<!-- 文件中文名: 游轮游信息保存-->
<template>
  <div class="CruiseInformationSave">
    <app-breadcrumb/>
    <div class="page-container">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="auto" size="mini">
        <el-form-item label="团队:" prop="team">
          {{ ruleForm.team }}
        </el-form-item>
        <el-form-item label="代办处编号:" prop="agentNo">
          {{ ruleForm.agentNo }}
        </el-form-item>
        <el-form-item label="代办处姓名:" prop="agentName">
          {{ ruleForm.agentName }}
        </el-form-item>
        <el-form-item label="资格人姓名:" prop="qualifiedName">
          {{ ruleForm.qualifiedName }}
        </el-form-item>
        <el-form-item label="资格人编号:" prop="qualifiedNo">
          <el-input ref="qualifiedNoIpt" v-model.trim="ruleForm.qualifiedNo" placeholder="请填写经销商编号"
                    style="width:195px;" :disabled="formParams.status==='view'||memberDisable" @blur="ruleForm.stopDate = ''"></el-input>
          <span :style="ruleForm.canUseNum>0?'color: green':'color: red'"> {{ ruleForm.canUseNum }}</span>
        </el-form-item>
        <el-form-item label="是否转让:" prop="isTransfer">
          <el-radio-group v-model="ruleForm.isTransfer" @change="isTransferChange">
            <el-radio label="0" :disabled="formParams.status==='view'">否</el-radio>
            <el-radio label="1" :disabled="formParams.status==='view'">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="出行人身份证号:" prop="idCard">
          <el-input v-model.trim="ruleForm.idCard" placeholder="请填写身份证号" style="width:195px;"
                    :disabled="formParams.status==='view'" @blur="idCardBlur($event.target.value)"></el-input>
          <span style="color: blue;" v-if="goOuterAge"> {{ goOuterAge }}岁</span>
        </el-form-item>
        <el-form-item label="上传出行人身份证图片(正反两张):" class="hong-xing">
          <el-upload
              :disabled="formParams.status==='view'"
              :action="uploadUrl"
              name="multipartFile"
              :limit="2"
              multiple
              list-type="picture-card"
              :headers="headers"
              :file-list="fileList2"
              :before-upload="beforeUpload"
              :on-success="handleUploadSuccess2"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove2">
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip" style="margin:0;">只能上传jpg/png文件，且不超过5MB，最多2张</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="出行人编号:">
          <el-input v-model.trim="ruleForm.memberNo" placeholder="请填写出行人编号" style="width:195px;"
                    :disabled="formParams.status==='view'||ruleForm.isTransfer==='0'" @blur="checkMemberNo"></el-input>
          <span :style="checkMemberNoStr==='x' ? 'color: red': 'color: green'"> {{ checkMemberNoStr }}</span>
        </el-form-item>
        <el-form-item label="出行人姓名:" prop="memberName">
          <el-input v-model.trim="ruleForm.memberName" placeholder="请填写中文姓名"
                    style="width:195px;"
                    :disabled="formParams.status==='view'"></el-input>
        </el-form-item>
        <el-form-item label="出行人拼音姓名" prop="pinyinXingMing">
          <el-input v-model.trim="ruleForm.pinyinXingMing" placeholder="请填写英文大写字母拼音"
                    style="width:195px;"
                    :disabled="formParams.status==='view'"></el-input>
        </el-form-item>
        <el-form-item label="出行人性别:" prop="sex">
          <el-radio-group v-model="ruleForm.sex">
            <el-radio label="1" :disabled="formParams.status==='view'">男</el-radio>
            <el-radio label="0" :disabled="formParams.status==='view'">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="出行人出生年月:" prop="born">
          <el-date-picker
              :disabled="formParams.status==='view'"
              v-model="ruleForm.born"
              value-format="yyyy/MM/dd"
              type="date"
              size="mini"
              style="width:195px;"
              placeholder="请填写出生年月"
              @change="bornChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="goOuterAge>=70" label="体检报告:" class="hong-xing">
          <el-upload
              :disabled="formParams.status==='view'"
              :action="uploadUrl"
              name="multipartFile"
              :limit="10"
              multiple
              list-type="picture-card"
              :headers="headers"
              :file-list="fileList4"
              :before-upload="beforeUpload"
              :on-success="handleUploadSuccess4"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove4">
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip" style="margin:0;">只能上传jpg/png文件，且不超过5MB，最多10张</div>
          </el-upload>
        </el-form-item>
        <el-form-item v-if="goOuterAge>=70" label="免责声明:" class="hong-xing">
          <el-upload
              :disabled="formParams.status==='view'"
              :action="uploadUrl"
              name="multipartFile"
              :limit="10"
              multiple
              list-type="picture-card"
              :headers="headers"
              :file-list="fileList5"
              :before-upload="beforeUpload"
              :on-success="handleUploadSuccess5"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove5">
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip" style="margin:0;">只能上传jpg/png文件，且不超过5MB,最多10张</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="出行人出生地:" prop="bornPlace">
          <el-input v-model.trim="ruleForm.bornPlace" placeholder="请填写出行人出生地"
                    style="width:195px;" :disabled="formParams.status==='view'"></el-input>
        </el-form-item>
        <el-form-item label="上传出行人护照图片:" class="hong-xing">
          <el-upload
              :disabled="formParams.status==='view'"
              :action="uploadUrl"
              name="multipartFile"
              :limit="1"
              multiple
              list-type="picture-card"
              :headers="headers"
              :file-list="fileList3"
              :before-upload="beforeUpload"
              :on-success="handleUploadSuccess3"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove3">
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip" style="margin:0;">只能上传jpg/png文件，且不超过5MB，最多1张</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="出行人护照号码:">
          <el-input v-model.trim="ruleForm.passport" placeholder="请填写户籍号码" style="width:195px;"
                    :disabled="formParams.status==='view'"></el-input>
        </el-form-item>
        <el-form-item label="出行人护照签发地:">
          <el-input v-model.trim="ruleForm.passportPlace" placeholder="请填写护照签发地"
                    style="width:195px;" :disabled="formParams.status==='view'"></el-input>
        </el-form-item>
        <el-form-item label="出行人护照签发日期:">
          <el-date-picker
              :disabled="formParams.status==='view'"
              v-model="ruleForm.issueDate"
              value-format="yyyy/MM/dd"
              type="date"
              size="mini"
              style="width:195px;"
              placeholder="请填写签发日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="出行人护照有效期:">
          <el-date-picker
              :disabled="formParams.status==='view'"
              v-model="ruleForm.stopDate"
              value-format="yyyy/MM/dd"
              type="date"
              size="mini"
              style="width:195px;"
              :picker-options="{disabledDate}"
              :default-value="newDate"
              placeholder="请填写有效期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="护照备注信息:">
          <el-input :disabled="formParams.status==='view'" style="width:195px;" type="textarea" :rows="3" v-model="ruleForm.passportRemark"/>
        </el-form-item>
        <el-form-item v-if="isAgent" label="房型:" prop="roomType">
          {{$lt(initDict.roomtype,ruleForm.roomType)}}
        </el-form-item>
        <el-form-item v-if="isAgent" label="同住人:" prop="residents">
          <el-input v-model.trim="ruleForm.residents" placeholder="" style="width:195px;" disabled></el-input>
        </el-form-item>
        <el-form-item v-if="isAgent" label="房号:" prop="roomNo">
          <el-input v-model.trim="ruleForm.roomNo" placeholder="" style="width:195px;" disabled></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="default" icon="el-icon-close" @click="onBack">返回</el-button>
          <el-button :loading="doLoading" type="primary" icon="el-icon-check" @click="onSave"
                     v-if="formParams.status!=='view'">保存
          </el-button>
        </el-form-item>
      </el-form>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
  </div>
</template>

<script>

import {getToken} from "@/utils/auth";
import {IdCard, memberPassportMemberInfo, memberPassportSave, memberPassportViewById} from "@/api/mi/memberPassport";
import {mapGetters} from 'vuex'
import {findMemberInfo} from "@/api/common/common";

export default {
  name: 'CruiseInformationSave',
  // import引入的组件需要注入到对象中才能使用",
  components: {},
  // 作为子组件传入数据
  props: {
    typeNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    const checkUpperCase = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('不能为空'));
      }
      // 正则表达式，匹配大写英文字母
      const regex = /^[A-Z]+$/;
      // 使用 test 方法检查字符串是否匹配正则表达式
      if (regex.test(value)) {
        callback();
      } else {
        callback(new Error('请输入大写英文字母拼音'))
      }
    };
    return {
      newDate: new Date(2025, 3, 14),
      limitTeam: ['晟世', '寰球', '龙腾', '群星'],
      initDict:{
        roomtype:'room.type'
      },
      goOuterAge: null,
      editData: null,
      checkMemberNoStr: '',
      memberDisable: false,
      formParams: {
        status: null,
      },
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      fileList5: [],
      dialogImageUrl: '',
      dialogVisible: false,
      doLoading: false,
      uploadPercent: 0,
      picFileName: '',
      uploadUrl: process.env.VUE_APP_BASE_API + '/miPicture/miPictureUpload',
      headers: {
        'Authorization': getToken()
      },
      active: 0,
      ruleForm: {
        canUseNum: '',
        memberNo: '',
        agentName: '',
        qualifiedName: '',
        agentNo: '',
        team: '',
        qualifiedNo: '',
        isTransfer: '',
        memberName: '',
        idCard: '',
        pinyinXingMing: '',
        sex: '',
        born: '',
        bornPlace: '',
        passport: '',
        nationality: '',
        passportPlace: '',
        issueDate: '',
        stopDate: '',
        roomType: '',
        residents: '',
        roomNo: '',
        passportRemark: ''
      },
      rules: {
        qualifiedNo: [
          {required: true, message: '请填写10位资格人编号', len: 10, trigger: ['change', 'blur']},
          {validator: this.checkQualifiedNo, trigger: 'blur'}
        ],
        memberNo: [
          {message: '请填写10位出行人编号', len: 10, trigger: ['change', 'blur']}
        ],
        isTransfer: [{required: true, message: '请选择是否转让', trigger: ['change', 'blur']}],
        memberName: [{
          required: true,
          message: '请填写中文姓',
          pattern: /^[\u4e00-\u9fa5]+$/,
          trigger: ['change', 'blur']
        }],
        name: [{required: true, message: '请填写中文名', pattern: /^[\u4e00-\u9fa5]+$/, trigger: ['change', 'blur']}],
        idCard: [{required: true, message: '请填写18位身份证号', len: 18, trigger: ['change', 'blur']}],
        pinyinXingMing: [
          {required: true, message: '请填写拼音姓,只支持英文大写字母拼音', trigger: ['change', 'blur']},
          {validator: checkUpperCase, trigger: 'blur'}
        ],
        sex: [{required: true, message: '请选择性别', trigger: ['change', 'blur']}],
        born: [{required: true, message: '请填写出生日期', trigger: ['change', 'blur']}],
        bornPlace: [
          {required: true, message: '请填写出行人出生地', trigger: ['change', 'blur']},
        ],
        passportPlace: [
          {required: true, message: '请填写护照签发地', trigger: ['change', 'blur']},
        ],
        passport: [{required: true, message: '请填写9位护照号码', len: 9, trigger: ['change', 'blur']}],
        issueDate: [{required: true, message: '请填写签发日期', trigger: ['change', 'blur']}],
        stopDate: [{required: true, message: '请填写有效期', trigger: ['change', 'blur']}],
      }
    }
  },
  computed: {
    ...mapGetters([
      'isAgent',
      'userCode',
    ]),
  },
  async created() {
    this.formParams = sessionStorage.getItem('CruiseInfo') ? JSON.parse(sessionStorage.getItem('CruiseInfo')) : {}
    console.log(this.formParams, '12this.formParams')
    if (this.formParams.status !== 'new') {
      let ViewEdit = await this.getViewEditData(this.formParams.id)
      if (this.formParams.status === 'edit') {
        this.editData = JSON.parse(JSON.stringify(ViewEdit))
      }
      if (ViewEdit.isTransfer === '1') {
        this.fileList1.push({
          name: ViewEdit.transferphoto,
          url: ViewEdit.transferphotoUrl,
          response: {data: {fileName: ViewEdit.transferphoto, url: ViewEdit.transferphotoUrl}}
        })
      }
      this.fileList2.push({
        name: ViewEdit.idphotoFront,
        url: ViewEdit.idphotoFrontUrl,
        response: {data: {fileName: ViewEdit.idphotoFront, url: ViewEdit.idphotoFrontUrl}}
      })
      this.fileList2.push({
        name: ViewEdit.idphotoBack,
        url: ViewEdit.idphotoBackUrl,
        response: {data: {fileName: ViewEdit.idphotoBack, url: ViewEdit.idphotoBackUrl}}
      })
      this.fileList3.push({
        name: ViewEdit.passportphoto,
        url: ViewEdit.passportphotoUrl,
        response: {data: {fileName: ViewEdit.passportphoto, url: ViewEdit.passportphotoUrl}}
      })
      if (ViewEdit.medicalReportMap.length) {
        for (let i = 0; i < ViewEdit.medicalReportMap.length; i++) {
          let key1 = Object.keys(ViewEdit.medicalReportMap[i])[0]
          let value1 = ViewEdit.medicalReportMap[i][key1]
          this.fileList5.push({
            name: key1,
            url: value1,
            response: {data: {fileName: key1, url: value1}}
          })
        }
      }
      if (ViewEdit.disclaimerMap.length) {
        for (let i = 0; i < ViewEdit.disclaimerMap.length; i++) {
          let key2 = Object.keys(ViewEdit.disclaimerMap[i])[0]
          let value2 = ViewEdit.disclaimerMap[i][key2]
          this.fileList4.push({
            name: key2,
            url: value2,
            response: {data: {fileName: key2, url: value2}}
          })
        }
      }
      this.goOuterAge = ViewEdit.age
      this.ruleForm = ViewEdit
      this.ruleForm.born = ViewEdit.born.split(' ')[0].replace(/-/g, '/')
      this.ruleForm.stopDate = ViewEdit.stopDate.split(' ')[0].replace(/-/g, '/')
      this.ruleForm.issueDate = ViewEdit.issueDate.split(' ')[0].replace(/-/g, '/')
      this.memberDisable = true
    } else {
      //如果是新增,并且是经销商登录,资格人为经销商本人
      if (!this.isAgent) {
        this.ruleForm.qualifiedNo = this.userCode
        this.$nextTick(() => {
          this.$refs.qualifiedNoIpt.focus()
          this.$refs.qualifiedNoIpt.blur()
          this.memberDisable = true
        })
      }
    }
  },
  methods: {
    idCardBlur(val) {
      if (val && val.length === 18) {
        IdCard({'id' : val}).then(res => {
          this.goOuterAge = res.data
        }).catch(() => {
          this.goOuterAge = null
        })
      } else {
        this.$message.error('请输入正确的18位身份证信息')
        return false
      }
    },
    bornChange() {
      if (this.goOuterAge > 75) {
        return this.$message.error('超过75岁,不能申请!')
      }
      if (this.goOuterAge >= 70) {
        return this.$message.info('超过70岁,请上传体检报告和免责声明!')
      }
    },
    disabledDate(time) {
      return time.getTime() < this.newDate.getTime()
    },
    isTransferChange() {
      if (this.ruleForm.isTransfer === '0') {
        this.ruleForm.memberNo = this.ruleForm.qualifiedNo
        this.checkMemberNo()
      } else {
        this.ruleForm.memberNo = ''
        this.checkMemberNoStr = ''
      }
    },
    checkQualifiedNo(rule, value, callback) {
      if (!value) {
        return callback(new Error('不能为空'));
      }
      memberPassportMemberInfo({memberNo: value}).then(res => {
        this.ruleForm.canUseNum = res.data.qualificationNum
        this.ruleForm.agentNo = res.data.agentNo
        this.ruleForm.agentName = res.data.agentName
        this.ruleForm.qualifiedName = res.data.memberName
        this.ruleForm.team = res.data.miTeam.teamName
        if (res.data.qualificationNum > 0) {
          callback()
        } else {
          if (this.formParams?.status === 'edit') {
            callback()
          } else {
            callback(new Error('此经销商已无资格!'))
          }
        }
        for (let a  = 0; a< this.limitTeam.length; a++) {
          if (this.ruleForm.team.indexOf( this.limitTeam[a]) > -1 )  {
            this.newDate = new Date(2025, 3, 21)
            return false
          } else {
            this.newDate = new Date(2025, 3, 14)
          }
        }
      }).catch(err => {
        callback(new Error('请求出错!'))
        console.error(err)
      })
    },
    checkMemberNo() {
      if (!this.ruleForm.memberNo) {
        this.checkMemberNoStr = ''
        return false
      }
      findMemberInfo({code: this.ruleForm.memberNo}).then(res => {
        console.log(res.data, 'checkMemberNo')
        this.checkMemberNoStr = res.data.memberName
      }).catch(err => {
        this.checkMemberNoStr = 'x'
        console.error(err)
      })
    },
    beforeUpload(file) {
      const acceptTypes = ['image/png', 'image/jpeg']
      const isJPG = acceptTypes.some(item => item === file.type)
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传图片只能是jpg/png格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过5MB')
        return false
      }
      return isJPG && isLt2M
    },
    handleUploadSuccess1(response, file, fileList) {
      this.fileList1 = fileList
    },
    handleRemove1(file, fileList) {
      this.fileList1 = fileList
    },
    handleUploadSuccess2(response, file, fileList) {
      this.fileList2 = fileList
    },
    handleRemove2(file, fileList) {
      this.fileList2 = fileList
    },
    handleUploadSuccess3(response, file, fileList) {
      this.fileList3 = fileList
    },
    handleRemove3(file, fileList) {
      this.fileList3 = fileList
    },
    handleUploadSuccess4(response, file, fileList) {
      this.fileList4 = fileList
    },
    handleRemove4(file, fileList) {
      this.fileList4 = fileList
    },
    handleUploadSuccess5(response, file, fileList) {
      this.fileList5 = fileList
    },
    handleRemove5(file, fileList) {
      this.fileList5 = fileList
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    onSave() {
      console.log(this.goOuterAge, 'this.goOuterAge')
      if (!this.goOuterAge && this.ruleForm.idCard !== 18) {
        this.$message.error('请输入正确的18位身份证信息')
        return false
      }
      //身份证正反图片链接
      this.ruleForm.idphotoFront = this.fileList2[0]?.response?.data?.fileName
      this.ruleForm.idphotoBack = this.fileList2[1]?.response?.data?.fileName
      //护照图片链接
      this.ruleForm.passportphoto = this.fileList3[0]?.response?.data?.fileName

      if (!this.ruleForm.idphotoFront || !this.ruleForm.idphotoBack || !this.ruleForm.passportphoto) {
        return this.$message.error('请上传身份证正反图片或者护照图片')
      }

      if (this.goOuterAge > 75) {
        return this.$message.error('年龄大于75岁!')
      }

      if (this.goOuterAge >= 70) {
        if (!this.fileList4.length || !this.fileList5.length) {
          return this.$message.error('年龄大于70岁,请上传体检报告和免责声明!')
        }
        this.ruleForm.disclaimer = this.fileList4.map(item => item.response.data.fileName).join(';')
        this.ruleForm.medicalReport = this.fileList5.map(item => item.response.data.fileName).join(';')
      } else {
        this.ruleForm.disclaimer = ''
        this.ruleForm.medicalReport = ''
      }
      // console.log(this.ruleForm, 'this.ruleForm')
      this.ruleForm.nationality = '中国'
      // ----编辑状态判断是否进行修改开始----
      if (this.formParams.status === 'edit') {
        const keys1 = Object.keys(this.ruleForm)
        let isSame = true
        for (let key of keys1) {
          const val1 = this.ruleForm[key]
          const val2 = this.editData[key]
          if (val1 !== val2) {
            // console.log(key, val1, val2)
            isSame = false
          }
        }
        if (isSame) {
          this.$message.error('您没有修改任何信息!')
          return
        }
      }
      // ----编辑状态判断是否进行修改结束----
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$confirm('确定提交信息?', '信息录入', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'success'
          }).then(() => {
            this.doLoading = true
            memberPassportSave({...this.ruleForm}).then((res) => {
              this.doLoading = false
              if (res.success) {
                this.$message.success('录入成功')
                this.$router.go(-1)
              }
            }).catch(err => {
              console.warn(err)
              this.doLoading = false
            })
          })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '操作取消'
                })
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    onBack() {
      this.$router.go(-1)
    },
    getViewEditData(id) {
      return new Promise((resolve, reject) => {
        this.doLoading = true
        memberPassportViewById({id}).then((res) => {
          this.doLoading = false
          resolve(res.data)
        }).catch(err => {
          this.doLoading = false
          console.warn(err)
          reject(err)
        })
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.hong-xing {
  ::v-deep.el-form-item__label:before {
    content: "*";
    color: #F56C6C;
    margin-right: 4px;
  }
}
</style>
